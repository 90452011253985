@import "styles/abstracts/index";
.box {
  min-width: 500px;
}

@include md-down() {
  .box {
    min-width: 400px;
  }
}

@include sm-down() {
  .box {
    min-width: 300px;
  }
}

@include xxl-up() {
  .box {
    min-width: 600px;
  }
}
.lookupListWrap {
  background-color: var(--white);
  margin-top: 16px;
  border-radius: 8px;
  height: 400px;
  overflow-y: scroll;

  @include md-down() {
    height: 280px;
  }

  .listContent {
    padding: 1.5em;
  }

  .subItem,
  .subItemWrap {
    list-style: none;
  }

  .subItem {
    padding-left: 38px;
  }
}

.inputSearchWrap {
  position: relative;
  outline: 2px solid transparent;
  outline-offset: 2px;

  .inputWrap,
  .input {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  .inputError.inputError {
    border: 2px solid var(--red);
  }
  .inputWrap {
    min-height: 40px;
  }
  .input {
    padding-left: 40px;
    padding-right: 74px;
    height: 40px;
    box-shadow: none;
    border: 0;
    font-family: var(--font-secondary);
    &:hover,
    &:focus {
      box-shadow: var(--shadow);
    }
  }
  .icon {
    position: absolute;
    left: 8px;
    top: 8px;
  }

  .checkButton {
    background-color: var(--black);
    position: absolute;
    right: 8px;
    top: 4px;
    padding: 0 12px;
    border-radius: 8px;
    font-size: 14px;
    color: var(--white);
    height: 32px;
  }

  .checkButtonDisabled {
    background-color: var(--grey-dark);
    position: absolute;
    right: 8px;
    top: 4px;
    padding: 0 12px;
    border-radius: 8px;
    font-size: 14px;
    color: var(--white);
    height: 32px;
    cursor: not-allowed;
  }
}
.loading {
  padding: 24px !important;
}
